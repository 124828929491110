<template>
  <div class="DAV-workshop__space">
    <component-title>
      <h3 slot="title">
        지난 저장 내역
        <tooltip
          v-if="$route.query.mode !== 'partner'"
          top="30px"
          :text="`<span class='bold'>최근 저장된 테마 기준</span> 일별 시간 단위로 내역을 제공하고 있습니다.<br/>
          ･ 테마 저장 히스토리는 최대 20개 까지 저장되고, 개수가 초과될 경우 가장 먼저 저장했던 내역 순으로 자동 삭제됩니다.<br/>
          ･ 보관 기간은 전일과 당일 2일간의 저장 데이터를 보관합니다.`"
        />
        <tooltip
          v-if="$route.query.mode === 'partner'"
          top="30px"
          :text="`<span class='bold'>최근 저장된 테마 기준</span> 일별 시간 단위로 내역을 제공하고 있습니다.<br/>
          ･ 테마 저장 히스토리는 최대 20개 까지 저장되고, 개수가 초과될 경우 가장 먼저 저장했던 내역 순으로 자동 삭제됩니다.`"
        />
      </h3>
    </component-title>
    <spinner v-if="isLoading" :propsPartial="true" />
    <div class="DAV-blockClass" v-else>
      <div class="errorLayout errorLayout__content" v-if="!historyDate.length">
        <p>저장 내역이 없습니다.</p>
      </div>
      <p class="saveHistory__text">
        ※ 저장 히스토리 목록에서 원하는 저장시점을 선택하세요.
      </p>
      <ol class="saveHistory">
        <li v-for="(date, i) in historyDate" :key="i">
          <div class="saveHistory__date">
            <span>
              {{ date.split(".")[0].substr(-2) }}.
              {{ date.split(".")[1] }}
            </span>
            <b>
              {{ date.split(".")[2] }}
            </b>
          </div>
          <ol class="saveHistory__list">
            <li v-for="(item, j) in historyList(date)" :key="j">
              <div>
                <span class="saveHistory__list--time">
                  {{ item.createdTime }}
                </span>
                <button
                  class="saveHistory__list--delete"
                  @click="historyDelete(item.id, item)"
                >
                  삭제
                </button>
                <button
                  class="saveHistory__list--apply"
                  @click="historyPreview(item.id, item.data)"
                >
                  적용
                </button>
              </div>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleConfirm = createNamespacedHelpers("ModuleConfirm");
const ModuleTheme = createNamespacedHelpers("ModuleTheme");
const ModuleEditor = createNamespacedHelpers("ModuleEditor");

const PerPage = 20;

export default {
  data() {
    return {
      isLoading: false,
      historyId: null,
      historyDate: new Set(),
      historyData: {},
      solutionName: null
    };
  },
  watch: {
    // 테마 저장시 저장 히스토리 목록 호출
    getThemeLoadData() {
      this.$route.query.mode === "partner"
        ? this.partnerHistoryList()
        : this.saveHistoryList();
    },
    // 저장 히스토리 목록
    getSaveHistoryListData(data) {
      this.isLoading = false;
      this.historyData = data.list;

      for (let v of this.historyData) {
        this.$set(
          v,
          "createdDate",
          this.$options.filters.date(v.createdAt).substr(0, 10)
        );
        this.$set(
          v,
          "createdTime",
          this.$options.filters.date(v.createdAt).substr(-5)
        );
      }

      this.historyData.forEach(v => {
        this.historyDate.add(v.createdDate);
      });
      this.historyDate = Array.from(this.historyDate);
    },
    getSavePartnerHistoryListData(data) {
      this.isLoading = false;
      this.historyData = data.list;

      for (let v of this.historyData) {
        this.$set(
          v,
          "createdDate",
          this.$options.filters.date(v.createdAt).substr(0, 10)
        );
        this.$set(
          v,
          "createdTime",
          this.$options.filters.date(v.createdAt).substr(-5)
        );
      }

      this.historyData.forEach(v => {
        this.historyDate.add(v.createdDate);
      });
      this.historyDate = Array.from(this.historyDate);
    }
  },
  computed: {
    ...ModuleTheme.mapGetters([
      "getSaveHistoryListData",
      "getThemeLoadData",
      "getSavePartnerHistoryListData"
    ]),
    ...ModuleEditor.mapGetters(["getThemeId", "getSolutionListData"])
  },
  methods: {
    // 저장 히스토리 그룹
    historyList(date) {
      return this.historyData.filter(v => v.createdDate === date);
    },
    // 저장 히스토리 목록
    saveHistoryList() {
      this.isLoading = true;

      let payload = {};
      payload.themeId = this.getThemeId;
      payload.perPage = PerPage;

      this.actSaveHistoryList(payload).then(() => {});
    },
    // 파트너 저장 히스토리 목록
    partnerHistoryList() {
      this.isLoading = true;

      let payload = {};
      payload.themeId = this.getThemeId;
      payload.perPage = PerPage;

      this.actSavePartnerHistoryList(payload).then(() => {});
    },
    // 저장 히스토리 삭제 confirm
    historyDelete(historyId, data) {
      this.historyId = historyId;

      this.setConfirm({
        isVisible: true,
        message: `선택한 저장 내역을 삭제하시겠습니까?`,
        messageBtn: `삭제하기`,
        event: () =>
          this.$route.query.mode === "partner"
            ? this.partnerHistoryDelete()
            : this.saveHistoryDelete(data)
      });
    },
    // 저장 히스토리 삭제
    saveHistoryDelete(data) {
      this.isLoading = true;
      this.historyDate = new Set();

      let payload = {};
      payload.themeId = this.getThemeId;
      payload.historyId = this.historyId;

      this.actSaveHistoryDelete(payload).then(response => {
        this.isLoading = false;

        switch (response.status) {
          case 204:
            // 솔루션 네임
            for (let solution of this.getSolutionListData) {
              if (data.data.theme.solutionId === solution.id) {
                this.solutionName = solution.name;
              }
            }
            this.$store.dispatch("ModuleUser/actUserLog", {
              eventName: "백업 테마 삭제",
              requestLocation:
                "큐픽 > 어드민 > 테마관리 > 편집 > 저장 히스토리",
              solutionName: this.solutionName,
              themeId: this.getThemeId,
              createdAt: data.createdAt
            });
            this.setAlert({
              isVisible: true,
              message: `삭제가 완료되었습니다.`
            });
            this.saveHistoryList();
            break;

          default:
            alert(response.data.errors[0].userMsg);
            break;
        }
      });
    },
    // 파트너 저장 히스토리 삭제
    partnerHistoryDelete() {
      this.isLoading = true;
      this.historyDate = new Set();

      let payload = {};
      payload.themeId = this.getThemeId;
      payload.historyId = this.historyId;

      this.actSavePartnerHistoryDelete(payload).then(response => {
        this.isLoading = false;

        switch (response.status) {
          case 204:
            this.setAlert({
              isVisible: true,
              message: `삭제가 완료되었습니다.`
            });
            this.partnerHistoryList();
            break;

          default:
            alert(response.data.errors[0].userMsg);
            break;
        }
      });
    },
    // 저장 히스토리 미리보기
    historyPreview(historyId, data) {
      this.historyId = historyId;

      this.setPopup({
        page: "HistoryPreview",
        index: historyId,
        value: data
      });
    },

    ...ModuleAlert.mapMutations(["setAlert"]),
    ...ModuleConfirm.mapMutations(["setConfirm"]),
    ...ModuleTheme.mapActions([
      "actSaveHistoryList",
      "actSaveHistoryDelete",
      "actSavePartnerHistoryList",
      "actSavePartnerHistoryDelete"
    ]),
    ...ModuleEditor.mapMutations(["setPopup"])
  },
  mounted() {
    this.$route.query.mode === "partner"
      ? this.partnerHistoryList()
      : this.saveHistoryList();
  },
  components: {
    Spinner: () => import("@/components/basis/spinner.vue"),
    ComponentTitle: () => import("@/components/editor/ComponentTitle.vue")
  }
};
</script>

<style lang="scss">
.DAV-blockClass {
  top: 56px !important;
}
.saveHistory__text {
  padding: 16px;
  margin-top: 8px;
  margin-bottom: -8px;
  font-size: 14px;
  color: #8d8d8d;
}
.saveHistory {
  margin: 0 16px 24px;
  padding: 0;
  list-style-type: none;

  > li {
    position: relative;
    margin-top: 16px;
  }
  &__date {
    position: sticky;
    top: 3px;
    left: 0;
    width: 45px;
    color: #484948;
    text-align: center;

    span {
      display: block;
      margin-bottom: 3px;
      font-size: 14px;
      line-height: 16px;
    }
    b {
      display: block;
      font-size: 32px;
      font-weight: 700;
      line-height: 32px;
    }
  }
}
.saveHistory__list {
  margin-top: -59px;
  padding-left: 57px;

  > li {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    height: 56px;
    margin-top: 8px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  }
  &--time {
    margin-left: 16px;
  }
  &--time::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    background-image: url($path + "bul_history_time.svg");
    background-size: 16px 16px;
    background-repeat: no-repeat;
    vertical-align: -2px;
  }
  &--delete,
  &--apply {
    position: absolute;
    top: 15px;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -9999px;
  }
  &--delete {
    right: 64px;
    background-image: url($path + "bul_history_delete.svg");
  }
  &--apply {
    right: 8px;
    background-image: url($path + "bul_history_apply.svg");
  }
  &--apply::before {
    content: "";
    position: absolute;
    top: 0;
    right: 38px;
    height: 24px;
    border-left: 1px solid #dcdcdc;
  }
}
</style>
