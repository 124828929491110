var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-workshop__space"
  }, [_c('component-title', [_c('h3', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" 지난 저장 내역 "), _vm.$route.query.mode !== 'partner' ? _c('tooltip', {
    attrs: {
      "top": "30px",
      "text": "<span class='bold'>\uCD5C\uADFC \uC800\uC7A5\uB41C \uD14C\uB9C8 \uAE30\uC900</span> \uC77C\uBCC4 \uC2DC\uAC04 \uB2E8\uC704\uB85C \uB0B4\uC5ED\uC744 \uC81C\uACF5\uD558\uACE0 \uC788\uC2B5\uB2C8\uB2E4.<br/>\n        \uFF65 \uD14C\uB9C8 \uC800\uC7A5 \uD788\uC2A4\uD1A0\uB9AC\uB294 \uCD5C\uB300 20\uAC1C \uAE4C\uC9C0 \uC800\uC7A5\uB418\uACE0, \uAC1C\uC218\uAC00 \uCD08\uACFC\uB420 \uACBD\uC6B0 \uAC00\uC7A5 \uBA3C\uC800 \uC800\uC7A5\uD588\uB358 \uB0B4\uC5ED \uC21C\uC73C\uB85C \uC790\uB3D9 \uC0AD\uC81C\uB429\uB2C8\uB2E4.<br/>\n        \uFF65 \uBCF4\uAD00 \uAE30\uAC04\uC740 \uC804\uC77C\uACFC \uB2F9\uC77C 2\uC77C\uAC04\uC758 \uC800\uC7A5 \uB370\uC774\uD130\uB97C \uBCF4\uAD00\uD569\uB2C8\uB2E4."
    }
  }) : _vm._e(), _vm.$route.query.mode === 'partner' ? _c('tooltip', {
    attrs: {
      "top": "30px",
      "text": "<span class='bold'>\uCD5C\uADFC \uC800\uC7A5\uB41C \uD14C\uB9C8 \uAE30\uC900</span> \uC77C\uBCC4 \uC2DC\uAC04 \uB2E8\uC704\uB85C \uB0B4\uC5ED\uC744 \uC81C\uACF5\uD558\uACE0 \uC788\uC2B5\uB2C8\uB2E4.<br/>\n        \uFF65 \uD14C\uB9C8 \uC800\uC7A5 \uD788\uC2A4\uD1A0\uB9AC\uB294 \uCD5C\uB300 20\uAC1C \uAE4C\uC9C0 \uC800\uC7A5\uB418\uACE0, \uAC1C\uC218\uAC00 \uCD08\uACFC\uB420 \uACBD\uC6B0 \uAC00\uC7A5 \uBA3C\uC800 \uC800\uC7A5\uD588\uB358 \uB0B4\uC5ED \uC21C\uC73C\uB85C \uC790\uB3D9 \uC0AD\uC81C\uB429\uB2C8\uB2E4."
    }
  }) : _vm._e()], 1)]), _vm.isLoading ? _c('spinner', {
    attrs: {
      "propsPartial": true
    }
  }) : _c('div', {
    staticClass: "DAV-blockClass"
  }, [!_vm.historyDate.length ? _c('div', {
    staticClass: "errorLayout errorLayout__content"
  }, [_c('p', [_vm._v("저장 내역이 없습니다.")])]) : _vm._e(), _c('p', {
    staticClass: "saveHistory__text"
  }, [_vm._v(" ※ 저장 히스토리 목록에서 원하는 저장시점을 선택하세요. ")]), _c('ol', {
    staticClass: "saveHistory"
  }, _vm._l(_vm.historyDate, function (date, i) {
    return _c('li', {
      key: i
    }, [_c('div', {
      staticClass: "saveHistory__date"
    }, [_c('span', [_vm._v(" " + _vm._s(date.split(".")[0].substr(-2)) + ". " + _vm._s(date.split(".")[1]) + " ")]), _c('b', [_vm._v(" " + _vm._s(date.split(".")[2]) + " ")])]), _c('ol', {
      staticClass: "saveHistory__list"
    }, _vm._l(_vm.historyList(date), function (item, j) {
      return _c('li', {
        key: j
      }, [_c('div', [_c('span', {
        staticClass: "saveHistory__list--time"
      }, [_vm._v(" " + _vm._s(item.createdTime) + " ")]), _c('button', {
        staticClass: "saveHistory__list--delete",
        on: {
          "click": function click($event) {
            return _vm.historyDelete(item.id, item);
          }
        }
      }, [_vm._v(" 삭제 ")]), _c('button', {
        staticClass: "saveHistory__list--apply",
        on: {
          "click": function click($event) {
            return _vm.historyPreview(item.id, item.data);
          }
        }
      }, [_vm._v(" 적용 ")])])]);
    }), 0)]);
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }